<template>
  <div>
    <maintopview />
    <div class="container-lg p-bg-linear-green p-0 p-lg-2">
      <div class="pt-global-header pb-global-header mt-5">
        <hometop />
      </div>
      <!-- <div class="pt-global-header pb-global-header mt-5 text-center">
        <h1 class="fw-bold xl-fs-f-45rem">
          免费开始使用，<br />之后 3 个月优惠月费 $1
        </h1>
        <p class="fs-f-125 mt-5">无需提供信用卡资料，免费试用 Betnet 3 天</p>

        <div
          class="my-bg-white rounded-5 d-flex justify-content-between ps-4 pe-1 border-1 border-style py-lg-1 py-3 boder-black mx-auto mt-5 mb-3"
          style="width: 498px"
        >
          <input
            type="email"
            name="email"
            autocomplete="on"
            class="border-0 input-text-focus"
            :placeholder="$t('text_introduce169')"
            style="width: 18rem"
          />
          <div class="d-lg-block d-none">
            <button
              class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap"
              name="button"
              style="padding-top: 16px; padding-bottom: 16px"
              type="submit"
            >
               {{$t("text_free_use")}}
            </button>
          </div>
        </div>

        <small>输入电子邮件，即表示同意接收 Betnet 行销电子邮件。</small>
      </div> -->

      <div class="pt-global-header pb-global-header mt-3 text-center">
        <!-- <div
          class="w-25 mx-auto rounded-5"
          style="
            padding: 2px;
            background-image: linear-gradient(
              114.33deg,
              rgb(0, 255, 117) 18.38%,
              rgb(95, 209, 190) 55.15%,
              rgb(21, 188, 223) 88.27%
            );
          "
        >
          <div
            class="py-2 rounded-5 my-bg-white d-flex justify-content-around c-pointer"
          >
            <span
              class="fs-f-0875 my-bg-black my-text-white rounded-5 px-3 py-2 bg-hover-grey"
              >月缴</span
            >
            <span class="fs-f-0875 rounded-5 px-3 py-2"
              >年缴 (享减价 25% 优惠)</span
            >
          </div>
        </div> -->
        <div class="d-flex d-lg-none">
          <button
            type="button"
            class="flex-1 fw-bold h-14 border-1 border-style br-price-grey"
            @click="flag_which_pro = 0"
            :class="flag_which_pro == 0 ? 'br-price-green' : 'my-bg-white'"
          >
            Basic
          </button>
          <button
            type="button"
            class="flex-1 fw-bold h-14 border-1 border-style br-price-grey"
            @click="flag_which_pro = 1"
            :class="flag_which_pro == 1 ? 'br-price-green' : 'my-bg-white'"
          >
            Standard
          </button>
          <button
            type="button"
            class="flex-1 fw-bold h-14 border-1 border-style br-price-grey"
            @click="flag_which_pro = 2"
            :class="flag_which_pro == 2 ? 'br-price-green' : 'my-bg-white'"
          >
            Angel Partner
          </button>
        </div>
        <div class="mt-lg-3 d-lg-flex justify-content-between cp">
          <div
            class="lg-rounded-4 lg-bg-price-pro col-lg-4"
            v-show="flag_web_h5 || flag_which_pro == 0"
          >
            <div class="my-bg-white lg-rounded-4 pb-3 h-100">
              <div class="position-relative text-start px-4 pt-5">
                <div
                  class="position-absolute start-50 top-0 py-1 px-3 fs-f-0875 fw-bold"
                  :class="flag_web_h5 ? 'rounded-5' : 'w-100 text-center'"
                  :style="
                    flag_web_h5
                      ? 'transform: translate(-50%, -50%);'
                      : 'transform: translate(-50%, 0%);'
                  "
                  style="
                    background-image: linear-gradient(
                      46.87deg,
                      rgb(177, 245, 250) 0%,
                      rgb(77, 255, 158) 96.94%
                    );
                  "
                >
                  {{ $t("text_introduce124") }}
                </div>
                <h3 class="fw-bold xl-fs-f-1-875rem">Basic</h3>
                <p class="fw-bold fs-f-0875">{{ $t("text_introduce125") }}</p>
                <p class="fs-f-0875">
                  {{ $t("text_introduce126") }}
                </p>

                <div class="d-flex">
                  <span class="xl-fs-f-3-75rem fw-bold">{{
                    $t("text_introduce127")
                  }}</span>
                  <sub
                    class="d-flex flex-column justify-content-center ms-1 line-height-1rem fs-1rem"
                    ><span class="fw-bold" style="padding-top: 18px">{{
                      $t("text_introduce128")
                    }}</span></sub
                  >
                </div>

                <!-- <div class="fw-bold text-center mt-3">
                  {{$t("text_introduce129")}}
                </div> -->
              </div>
              <div
                class="bg-pro-green py-3 mt-4 border-style border-1"
                style="
                  border-image-slice: 1;
                  border-image-source: linear-gradient(
                    90deg,
                    rgb(104, 235, 209) 0%,
                    rgb(77, 253, 138) 100%
                  );
                "
              >
                <span class="fw-bold">{{ $t("text_introduce129") }}</span>
              </div>

              <div class="mt-5 px-4 text-start">
                <p class="fw-bold">Basic {{ $t("text_introduce130") }}</p>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce131") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce132") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce133") }}</span>
                </div>
              </div>

              <!-- <button
                class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap mt-5 w-75"
                name="button"
                style="padding-top: 16px; padding-bottom: 16px"
                type="submit"
              >
              {{$t("text_introduce134")}}
              </button> -->
            </div>
          </div>

          <div
            class="lg-rounded-4 border-1 border-grey border-style col-lg-4"
            v-show="flag_web_h5 || flag_which_pro == 1"
          >
            <div class="my-bg-white lg-rounded-4 pb-3 h-100">
              <div class="position-relative text-start px-4 pt-5">
                <h3 class="fw-bold xl-fs-f-1-875rem">Standard</h3>
                <p class="fw-bold fs-f-0875">{{ $t("text_introduce135") }}</p>
                <p class="fs-f-0875">{{ $t("text_introduce136") }}</p>

                <div class="d-flex">
                  <span class="xl-fs-f-3-75rem fw-bold">{{
                    $t("text_introduce137")
                  }}</span>
                  <sub
                    class="d-flex flex-column justify-content-center ms-1 line-height-1rem fs-1rem"
                    ><span class="fw-bold" style="padding-top: 18px">{{
                      $t("text_introduce138")
                    }}</span></sub
                  >
                </div>

                <!-- <div class="fw-bold text-center mt-3">
                  {{$t("text_introduce139")}}
                </div> -->
              </div>
              <div
                class="bg-pro-green py-3 mt-4 border-style border-1"
                style="
                  border-image-slice: 1;
                  border-image-source: linear-gradient(
                    90deg,
                    rgb(104, 235, 209) 0%,
                    rgb(77, 253, 138) 100%
                  );
                "
              >
                <span class="fw-bold">{{ $t("text_introduce139") }}</span>
              </div>

              <div class="mt-5 px-4 text-start">
                <p class="fw-bold">Standard {{ $t("text_introduce130") }}</p>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce131") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce140") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce133") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce141") }}</span>
                </div>
              </div>

              <!-- <button
                class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap mt-2 w-75"
                name="button"
                style="padding-top: 16px; padding-bottom: 16px"
                type="submit"
              >
              {{$t("text_introduce134")}}
              </button> -->
            </div>
          </div>

          <div
            class="lg-rounded-4 border-1 border-grey border-style col-lg-4"
            v-show="flag_web_h5 || flag_which_pro == 2"
          >
            <div class="my-bg-white lg-rounded-4 pb-3 h-100">
              <div class="position-relative text-start px-4 pt-5">
                <h3 class="fw-bold xl-fs-f-1-875rem">Angel Partner</h3>
                <p class="fw-bold fs-f-0875">{{ $t("text_introduce142") }}</p>
                <p class="fs-f-0875">
                  {{ $t("text_introduce143") }}
                </p>

                <div class="d-flex">
                  <span class="xl-fs-f-3-75rem fw-bold">{{
                    $t("text_introduce144")
                  }}</span>
                  <sub
                    class="d-flex flex-column justify-content-center ms-1 line-height-1rem fs-1rem"
                    ><span class="fw-bold" style="padding-top: 18px">{{
                      $t("text_introduce145")
                    }}</span></sub
                  >
                </div>

                <!-- <div class="fw-bold text-center mt-3">
                 {{$t("text_introduce146")}}
                </div> -->
              </div>
              <div
                class="bg-pro-green py-3 mt-4 border-style border-1"
                style="
                  border-image-slice: 1;
                  border-image-source: linear-gradient(
                    90deg,
                    rgb(104, 235, 209) 0%,
                    rgb(77, 253, 138) 100%
                  );
                "
              >
                <span class="fw-bold">{{ $t("text_introduce146") }}</span>
              </div>

              <div class="mt-5 px-4 text-start">
                <p class="fw-bold">
                  Angel Partner {{ $t("text_introduce130") }}
                </p>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce147") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce148") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce149") }}</span>
                </div>
                <div class="mb-3">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    class="me-3"
                  >
                    <path
                      d="M16.493 7.068a.75.75 0 10-1.06-1.061l1.06 1.06zm-7.456 6.395l-.53.53a.75.75 0 001.06 0l-.53-.53zm-3.47-4.53a.75.75 0 00-1.06 1.06l1.06-1.06zm9.866-2.926l-6.926 6.926 1.06 1.06 6.926-6.925-1.06-1.061zm-5.866 6.926l-4-4-1.06 1.06 4 4 1.06-1.06z"
                      fill="#1CAA86"
                    ></path>
                  </svg>
                  <span class="fw-bold">{{ $t("text_introduce150") }}</span>
                </div>
              </div>

              <!-- <button
                class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap mt-2 w-75"
                name="button"
                style="padding-top: 16px; padding-bottom: 16px"
                type="submit"
              >
              {{$t("text_introduce134")}}
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="mb-lg-5 mx-3 mx-lg-0">
        <h2 class="fw-bold xl-fs-f-40px fs-f-2-5rem">
          {{ $t("text_introduce151") }}
        </h2>
        <div class="d-flex cp mt-5 scroll-new">
          <div
            class="snap-center md-w-min-75 md-bg-price-blue"
            :class="!flag_web_h5 ? h5_class_intro : ''"
          >
            <div class="h-100">
              <img
                src="@/assets/img/main/16efea538ec4ab49f38fec582c588d53.svg"
                class="h-4 w-4"
              />
              <h3 class="fw-bold mt-3">{{ $t("text_introduce152") }}</h3>
              <div class="fs-f-1-125rem">
                {{ $t("text_introduce153") }}
              </div>
            </div>
          </div>
          <div
            class="snap-center md-w-min-75 md-bg-price-blue"
            :class="!flag_web_h5 ? h5_class_intro : ''"
          >
            <div class="h-100">
              <img
                src="@/assets/img/main/a0160a9073ac18b083d8d3ab2fd63866.svg"
                class="h-4 w-4"
              />
              <h3 class="fw-bold mt-3">{{ $t("text_introduce154") }}</h3>
              <div class="fs-f-1-125rem">
                {{ $t("text_introduce155") }}
              </div>
            </div>
          </div>
          <div
            class="snap-center md-w-min-75 md-bg-price-blue"
            :class="!flag_web_h5 ? h5_class_intro : ''"
          >
            <div class="h-100">
              <img
                src="@/assets/img/main/bb21be5f2da18019e4fd9dcd8dcdbda5.svg"
                class="h-4 w-4"
              />
              <h3 class="fw-bold mt-3">{{ $t("text_introduce156") }}</h3>
              <div class="fs-f-1-125rem">
                {{ $t("text_introduce157") }}
              </div>
            </div>
          </div>
          <div
            class="snap-center md-w-min-75 md-bg-price-blue"
            :class="!flag_web_h5 ? h5_class_intro : ''"
          >
            <div class="h-100">
              <img
                src="@/assets/img/main/423ce86b23399491b2c8d30583d6e8d5.svg"
                class="h-4 w-4"
              />
              <h3 class="fw-bold mt-3 text-nowrap">
                {{ $t("text_introduce158") }}
              </h3>
              <div class="fs-f-1-125rem">
                {{ $t("text_introduce159") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 mx-3 mx-lg-0">
        <h2 class="fw-bold xl-fs-f-3-75rem text-center md-fs-f-2-5rem">
          {{ $t("text_introduce160") }}
        </h2>
        <div class="d-lg-flex d-block cp rp mt-5 md-flex-wrap">
          <div
            class="my-lg-0 my-3 fl-ba-w"
            style="
              padding: 1px;
              border-radius: 8px;
              background-image: linear-gradient(
                114.33deg,
                rgb(0, 255, 117) 18.38%,
                rgb(95, 209, 190) 55.15%,
                rgb(21, 188, 223) 88.27%
              );
            "
          >
            <div class="h-100 my-bg-white p-5 rounded">
              <div class="text-center">
                <h3 class="fw-bold fs-f-3rem">Business</h3>
                <p class="fw-bold xl-fs-f-2rem">
                  $36,000/{{ $t("text_introduce161") }}
                </p>
                <p class="fs-f-1-125rem">
                  {{ $t("text_introduce162") }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="my-lg-0 my-3 fl-ba-w"
            style="
              padding: 1px;
              border-radius: 8px;
              background-image: radial-gradient(
                562.5% 1220.62% at 91.9% 32.5%,
                rgb(121, 145, 2) 0%,
                rgb(229, 255, 100) 100%
              );
            "
          >
            <div class="h-100 my-bg-white p-5 rounded">
              <div class="text-center">
                <h3 class="fw-bold fs-f-3rem">Betnetplus</h3>
                <p class="fw-bold xl-fs-f-2rem">
                  {{ $t("text_introduce163") }}
                </p>
                <p class="fs-f-1-125rem">
                  {{ $t("text_introduce164") }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="boder-black my-bg-black my-text-white my-lg-0 my-3 fl-ba-w"
            style="padding: 1px; border-radius: 8px"
          >
            <div class="h-100 p-5 rounded">
              <div class="text-center">
                <h3 class="fw-bold fs-f-3rem">Dealership</h3>
                <p class="fw-bold xl-fs-f-2rem">$360,000</p>
                <p class="fs-f-1-125rem">
                  {{ $t("text_introduce165") }} <br />{{
                    $t("text_introduce166")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-gradient-marquee xl-fs-f-1-875rem fs-f-125 fw-bold h-4 xl-h-5 lg-mt-8 mt-5"
    >
      <div class="animate-marquee d-flex align-items-center h-100 text-nowrap">
        <div v-for="l in 20" :key="l" class="d-inline-block">
          {{ $t("text_free_use") }}
          <span class="mx-5"
            ><svg
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="w-6 h-6 xl-w-8 xl-h-8"
            >
              <path
                d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>

    <qaview />

    <div class="my-bg-price-bottom">
      <div class="py-32 md-py-5 container">
        <h1 class="fw-bold text-center xl-fs-f-3-75rem md-fs-f-2-5rem">
          {{ $t("text_introduce167") }}
        </h1>
        <p
          class="fs-f-normal md-fs-f-1-125rem text-center mt-5 mx-auto"
          style="max-width: 720px"
        >
          {{ $t("text_introduce168") }}
        </p>
      </div>
    </div>

    <footerview
      class="p-3 bg-black my-text-white"
      style="--color-black: --color-white"
    />
  </div>
</template>

<script>
import maintopview from "./main_top.vue";
import footerview from "./footer.vue";
import qaview from "./common_qa.vue";
import hometop from "./home_top.vue";
export default {
  name: "price",
  data() {
    return {
      flag_web_h5: false,
      flag_which_pro: 0,
      web_class_intro: "",
      h5_class_intro: "p-3 border-1 border-style rounded-3",
    };
  },
  created() {
    if (screen.width >= 992) {
      this.flag_web_h5 = true;
    } else this.flag_web_h5 = false;
  },
  mounted() {},
  components: { maintopview, footerview, qaview, hometop },
  computed: {},
  methods: {},
  watch: {},
};
</script>
